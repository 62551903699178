<template>
  <lebo-dialog :title="isEdit ? '编辑组织': '新增组织'" :isShow="isAddVisible" width="35%" @close="closeAddVisible" footerSlot>
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="130px" class="demo-ruleForm">
      <el-form-item label="组织名称：" prop="organize_name">
        <el-input v-model.trim="addForm.organize_name" maxlength="30" placeholder="请输入组织名称" @input="(e) => (addForm.organize_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="所在位置：" prop="addres">
        <div class="button_box">
          <span style="margin-right: 15px;">{{ addForm.addres }}</span>
          <!-- <el-input style="margin-right: 15px;" v-model="addForm.addres" disabled v-show="addForm.addres" @input="(e) => (addForm.addres = validSpace(e))"></el-input> -->
          <lbButton icon="zhongxuan" :fill="true" @click="openMapDialog">{{ addForm.addres ? "重新选择" : "地图选点" }}</lbButton>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <lbButton type="goBack" icon="back" @click="isAddVisible = false">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" @click="submitForm">保 存</lbButton>
    </span>
    <!-- 地图标注对话框 -->
    <lebo-dialog append-to-body class="mapDialog" width="50%" title="地图选点" :isShow="addressInnerVisible" @close="closeAddress" footerSlot>
      <div class="searchMap">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable placeholder="请选择省" @change="provinceChange">
              <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable placeholder="请选择市" @change="cityChange">
              <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable placeholder="请选择区" @change="districtChange">
              <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字" @input="(e) => (keyword = validSpace(e))"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-button size="small" type="primary" @click="getAdress">搜索</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <div id="amapall" class="all-amap"></div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="setAddress()" >保 存</lbButton>
      </span>
    </lebo-dialog>
  </lebo-dialog>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import addressjs from '@/utils/city.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { newAddOrg, updateOrg, getOrgDetail, getProvinceList } from '@/api/administrative'
export default {
  props: ['msg', 'isEdit', 'id'],
  data () {
    return {
      isAddVisible: true, // 是否展示新增对话框
      addressInnerVisible: false, // 是否展示地图对话框
      keyword: '',
      map: {},
      infoWindow: {},
      marker: {},
      geocoder: {},
      autocomplete: null,
      addForm: {
        organize_name: '',
        longitude: '',
        latitude: '',
        addres: ''
      },
      communitySelectList: [], // 所属社区列表
      addFormRules: {
        organize_name: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        addres: [{ required: true, message: '请选择所在位置', trigger: 'blur' }]
      },
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [] // 区下拉列
    }
  },
  created () {
  },
  mounted () {
    console.log('-------=-=-=-this.isEdit = ', this.isEdit)
    console.log('-------=-=-=-this.id = ', this.id)
    if (this.isEdit) {
      this.requestDetail()
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    async requestDetail () {
      const res = await getOrgDetail({ organi_id: this.id })
      const {
        id,
        name,
        addres,
        longitude,
        latitude,
        province,
        province_code,
        city,
        city_code,
        district,
        district_code
      } = res.Data
      console.log('请求数据详情', res)
      this.addForm = {
        organize_name: name,
        longitude: longitude,
        latitude: latitude,
        addres: addres,
        province: province,
        province_code: province_code,
        city: city,
        city_code: city_code,
        district: district,
        district_code: district_code
      }
    },
    sureGo (status) {
      this.$emit('editoradd', status)
    },
    // 新增或编辑组织
    submitForm () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            this.addForm._id = this.id
            this.fnupdateOrg(this.addForm)
          } else {
            this.fnaddOrg(this.addForm)
          }
        } else {
          return false
        }
      })
    },
    // 新增组织请求
    async fnaddOrg (obj) {
      console.log(' 新增组织请求')
      const res = await newAddOrg(obj)
      if (res && res.Code === 200) {
        this.sureGo('add')
        this.isAddVisible = false
      }
    },
    // 编辑组织请求
    async fnupdateOrg (obj) {
      console.log('编辑组织请求-')
      const res = await updateOrg(obj)
      if (res && res.Code === 200) {
        this.sureGo('edit')
        this.isAddVisible = false
      }
    },
    // 打开地图对话框
    openMapDialog () {
      this.addressInnerVisible = true
      // console.log(this.addForm);
      // console.log(this.addressInfo);
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.addForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.addForm.province_code
        }, 2)
      }
      if (this.addForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.addForm.city_code
        }, 3)
      }
      this.addressInfo.longitude = this.addForm.longitude
      this.addressInfo.latitude = this.addForm.latitude
      this.addressInfo.address = this.addForm.addres
      this.addressInfo.province = this.addForm.province
      this.addressInfo.province_code = this.addForm.province_code
      this.addressInfo.city = this.addForm.city
      this.addressInfo.city_code = this.addForm.city_code
      this.addressInfo.district = this.addForm.district
      this.addressInfo.district_code = this.addForm.district_code
      this.initMap()
    },
    // 关闭对话框时触发
    closeAddVisible () {
      this.isAddVisible = false
      this.$refs.addFormRef.resetFields()
      this.$emit('close')
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map('amapall', {
          zoom: 12
        })
        this.map.clearMap()
        if (that.addressInfo.longitude && that.addressInfo.latitude) {
          that.map.setCenter([that.addressInfo.longitude, that.addressInfo.latitude])
        }
        this.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30)
        })
        this.marker = new AMap.Marker()
        that.map.plugin(['AMap.Geocoder'], () => {
          that.geocoder = new AMap.Geocoder({
            radius: 1000 // 范围，默认：500
          })
          if (that.addressInfo.address) {
            that.geocoder.getLocation(
              that.addressInfo.address,
              function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.showInfoWindow(lnglat, that.addressInfo.address)
                }
              }
            )
          }
        })
        this.map.on('click', (e) => {
          that.sureLnglatMarker(e.lnglat)
        })
        AMap.event.addListener(that.marker, 'click', function () {
          // console.log("=-=-=-=-=markerclick");
          that.infoWindow.open(that.map, that.marker.getPosition())
        })
        // 定位
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: false, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: false, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          // geolocation.getCityInfo(function (status, result) {
          //   console.log('定位中')

          //   if (status === 'complete') {
          //     console.log('定位成功', result)

          //     // onComplete(result)
          //   } else {
          //     console.log('定位失败', result)

          //     // onError(result)
          //   }
          // })
          geolocation.getCurrentPosition(function (status, result) {
            console.log('定位中...')
            if (status === 'complete') {
              console.log('定位成功')

              // onComplete(result)
            } else {
              console.log('定位失败', result)

              // onError(result)
            }
          })
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          // console.log(that.addressInfo.city);
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select) // 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            // console.log('=-=-=-=select-==-=-=', JSON.stringify(e));
            if (e.poi.location === '') {
              that.geocoder.getLocation(
                `${e.poi.district}${e.poi.name}${e.poi.address}`,
                function (status, result) {
                  if (status === 'complete' && result.geocodes.length) {
                    var lnglat = result.geocodes[0].location
                    that.sureLnglatMarker(lnglat)
                  }
                }
              )
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
      })
    },
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = [
        '台湾省',
        '澳门特别行政区',
        '香港特别行政区'
      ]
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log('=-=-=-=getAddress=', result)
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } =
            result.regeocode.addressComponent
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code
          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) {
              // 直辖市
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) {
              // 海南省 青海省
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) {
              // 台湾 香港 澳门
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.addressInfo.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          console.log('-=========that.addForm.city=', that.addressInfo.city)
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          // console.log("-=-=-=info-=-", JSON.stringify(that.info));
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
          that.showInfoWindow(lnglat, address)
        }
      })
    },
    showInfoWindow (lnglat, address) {
      var infoDiv = document.createElement('div')
      infoDiv.className = 'infodiv'
      var addressspan = document.createElement('span')
      addressspan.className = 'addressspan'
      addressspan.innerText = address
      infoDiv.appendChild(addressspan)
      // var confirmbtn = document.createElement("input");
      // confirmbtn.className = "input-btn";
      // confirmbtn.type = "button";
      // confirmbtn.value = "确定";
      // var that = this;
      // confirmbtn.onclick = function () {
      //   that.addressInnerVisible = false;
      // };
      // infoDiv.appendChild(confirmbtn);
      this.infoWindow.setContent(infoDiv)
      this.map.add(this.marker)
      this.map.setCenter(lnglat)
      this.marker.setPosition(lnglat)
      this.infoWindow.open(this.map, this.marker.getPosition())
    },
    // getAdress() {
    //   const that = this;
    //   this.geocoder.getLocation(that.keyword, function (status, result) {
    //     if (status === "complete" && result.geocodes.length) {
    //       var lnglat = result.geocodes[0].location;
    //       that.sureLnglatMarker(lnglat);
    //     }
    //   });
    // },
    setAddress () {
      console.log('setAddress', this.addressInfo)
      console.log('setAddress', this.addForm)
      this.addForm.longitude = this.addressInfo.longitude
      this.addForm.latitude = this.addressInfo.latitude
      this.addForm.addres = this.addressInfo.address
      this.addForm.province = this.addressInfo.province
      this.addForm.province_code = this.addressInfo.province_code
      this.addForm.city = this.addressInfo.city
      this.addForm.city_code = this.addressInfo.city_code
      this.addForm.district = this.addressInfo.district
      this.addForm.district_code = this.addressInfo.district_code
      this.addressInnerVisible = false
    },
    // 关闭地图对话框
    closeAddress () {
      this.addressInnerVisible = false
      this.keyword = ''
      this.addressInfo = {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      }
      this.provinceSelectList = []
      this.citySelectList = []
      this.districtSelectList = []
      this.addressInnerVisible = false
    },
    // 关闭对话框
    closeBatchAddDialogVisible () {
      this.$emit('close')
    }
    // querySearch(queryString, cb) {
    //   // var restaurants = this.plateNumberHeadList;
    //   // var results = queryString
    //   //   ? restaurants.filter(this.createFilter(queryString))
    //   //   : restaurants;
    //   // // 调用 callback 返回建议列表的数据
    //   // // console.log('results----', results);
    //   // if (results.length <= 0) this.addressInfo.address = "";
    //   // cb(results);
    // },
    // createFilter(queryString) {
    //   // return (restaurant) => {
    //   //   // console.log(restaurant);
    //   //   return (
    //   //     restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !==
    //   //     -1
    //   //   );
    //   // };
    // },
  }
}
</script>
<style scoped lang="less">
.el-dialog__wrapper.mapDialog {
  /deep/ .el-dialog {
    .el-dialog__body {
      padding: 0px;
      position: relative;
      .searchMap {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 99;
        .el-select.el-select--small {
          width: 150px;
        }
      }
    }
  }
}

.all-amap {
  width: 100%;
  height: 500px;
  /deep/.amap-maps {
    .amap-drags {
      .amap-overlays {
        .amap-info {
          .amap-info-contentContainer {
            .amap-info-content {
              background: blanchedalmond;
              .infodiv {
                display: flex;
                width: 150px;
                flex-direction: column;
                align-items: flex-start;
                .input-btn {
                  background: #409eff;
                  margin-top: 10px;
                  color: #ffffff;
                  font-weight: 400;
                  border: none;
                  border-radius: 2px;
                }
              }
            }
            .amap-info-sharp {
              bottom: 0;
              left: 50%;
              margin-left: -8px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid blanchedalmond;
            }
            .amap-info-close {
              position: absolute;
              top: 5px;
              right: 5px !important;
            }
          }
        }
      }
    }
  }
}
</style>
