<template>
  <lebo-dialog
    title="组织架构"
    :isShow="isVisible"
    width="30%"
    @close="closeVisible" footerSlot closeOnClickModal
  >
    <el-tree
      ref="treeRef"
      :data="treeData"
      :expand-on-click-node="false"
      :props="defaultProps"
      default-expand-all
      @node-click="nodeClick"
      node-key="id"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span :style="currentNodeKey === data.id ? 'color:#409eff' : ''">{{node.label}}</span>
        <span>
          <el-tooltip content="新增" placement="bottom" effect="light">
            <i class="iconfont lebo-xinzeng" @click="addDialog"></i>
          </el-tooltip>
          <el-tooltip content="编辑" placement="bottom" effect="light">
          <i class="iconfont lebo-bianji" @click="editItem(data)" v-if="node.level != 1"></i>
          </el-tooltip>
          <el-tooltip content="导入" placement="bottom" effect="light">
          <i class="iconfont lebo-daoru1" @click="importDialog = true"></i>
          </el-tooltip>
          <el-tooltip content="删除" placement="bottom" effect="light">
          <i class="iconfont lebo-shanchu1" @click="delItem(data.id)" v-if="node.level != 1"></i>
          </el-tooltip>
        </span>
      </span>
    </el-tree>
    <!-- 新增子类 -->
    <lebo-dialog
      width="30%"
      title="新增子类"
      :isShow="innerVisible"
      append-to-body
      @close="closeAddDialog" footerSlot
    >
      <el-form
        :model="addTreeForm"
        :rules="addTreeFormRules"
        ref="addTreeFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上一级：">
          <el-input v-model="addTreeForm.parent_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="架构名称：" prop="organize_name">
          <el-input
            maxlength="15"
            v-model="addTreeForm.organize_name"
            placeholder="请输入架构名称"
            @input="(e) => (addTreeForm.organize_name = validSpace(e))"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="innerVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="fnAddVillageArchitect">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 编辑 -->
    <lebo-dialog
      width="30%"
      title="编辑名称"
      :isShow="modifyVisible" @close="modifyVisible=false" footerSlot
      append-to-body
    >
      <el-form
        :model="editForm"
        ref="editFormRef"
        label-width="100px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item
          label="架构名称："
          prop="organize_name"
          :rules="[
            { required: true, message: '请输入架构名称', trigger: 'blur' },
          ]"
        >
          <el-input
            maxlength="15"
            v-model="editForm.organize_name"
            placeholder="请输入架构名称"
            @input="(e) => (editForm.organize_name = validSpace(e))"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="modifyVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="fnUpdateVillageArchitect">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- // 导入 -->
    <lebo-dialog
      width="30%"
      title="导入"
      :isShow="importDialog"
      append-to-body
      @close="importDialogClose" footerSlot
    >
      <el-form
        :model="importForm"
        :rules="importFormRules"
        ref="importFormRef"
        label-width="120px"
        class="import_form"
      >
        <el-form-item label="模板：" prop="file_path">
          <div class="upload_box">
            <el-upload
              class="logo-upload"
              action="''"
              :http-request="upload"
              accept=".xlsx"
              :show-file-list="true"
              :file-list="fileList"
            >
              <lbButton type="warning">点击上传</lbButton>
            </el-upload>
            <el-link
              href="/webmanage/xlsx/组织架构模板.xlsx"
              download="组织架构模板.xlsx"
              type="primary"
              style="margin-left: 15px;"
            >下载模板</el-link>
          </div>
        </el-form-item>
        <el-form-item label="失败原因：" v-if="errMsg" class="err_box">
          <div v-for="(item,index) in errMsg" :key="index">{{index + 1}}、{{item}}</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="importDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="importSubmit">导 入</lbButton>
      </span>
    </lebo-dialog>
  </lebo-dialog>
</template>
<script>
import {
  getOrgDetail,
  importArchitect,
  deleteOrganization,
  addChildrenOrg,
  updateOrg,
  uploadOrganizationFile
} from '@/api/administrative'
export default {
  props: ['id'],
  data () {
    return {
      currentNodeKey: '',
      isVisible: true,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      innerVisible: false,
      modifyVisible: false,
      addTreeForm: {
        organize_name: '', // 架构名称
        village_id: '', // 小区id
        parent_id: '', // 父级id
        parent_name: '' // 父级类别名称
      },
      addTreeFormRules: {
        organize_name: [
          { required: true, message: '请输入架构名称', trigger: 'blur' }
        ]
      },
      editForm: {
        _id: '', // 当前
        parent_id: '', // 父级id(判断地址用)
        organize_name: '' // 组织名称
      },
      importDialog: false,
      importForm: {
        parent_id: '', // 父级id
        file_path: '' // 文件路径
      },
      importFormRules: {
        file_path: [
          { required: true, message: '请选择文件上传', trigger: 'blur' }
        ]
      },
      // 文件列表
      fileList: [],
      errMsg: null // 失败原因
    }
  },
  created () {
    this.fngetVillageArchitectTreeList()
  },
  mounted () {},
  computed: {},
  watch: {},
  methods: {
    // 获取树控件
    async fngetVillageArchitectTreeList () {
      console.log('获取组织架构')
      const res = await getOrgDetail({
        organi_id: this.id
      })
      if (res && res.Code === 200) {
        console.log(res)
        this.treeData = [res.Data]
        if (this.currentNodeKey === '') {
          this.currentNodeKey = res.Data.id
          this.addTreeForm.parent_name = res.Data.name
          this.addTreeForm.parent_id = res.Data.id // 父级id
        }
      }
      // this.fngetSubLevelArchitectList(this.currentNodeKey);
    },
    // 获取当前展示节点的子节点
    // getSubLevelList(data) {
    //   data.forEach(item => {
    //     if (item.id === this.currentNodeKey) {
    //       this.tableData = item.children;
    //     } else {
    //       if (item.children && item.children.length > 0) {
    //         this.getSubLevelList(item.children);
    //       }
    //     }
    //   });
    // },
    // 点击节点
    nodeClick (data, node, key) {
      console.log('===========data=', data)
      console.log('===========data=', node)
      // 获取当前节点的id以及名称
      this.addTreeForm.parent_name = data.name
      this.addTreeForm.parent_id = data.id // 父级id
      this.currentNodeKey = data.id
      // 导入数据
      this.importForm.parent_id = data.id
    },
    // 打开新增对话框
    addDialog () {
      if (this.addTreeForm.parent_id === '') {
        this.$message.warning('请选择添加项目')
        return
      }
      this.innerVisible = true
    },
    // 新增子组织请求
    async fnAddVillageArchitect () {
      this.$refs.addTreeFormRef.validate(async valid => {
        if (valid) {
          console.log('新增请求---')
          var res = await addChildrenOrg({
            organize_name: this.addTreeForm.organize_name, // 组织名称
            parent_id: this.addTreeForm.parent_id // 父级id
          })
          if (res.Code === 200) {
            this.innerVisible = false
            this.fngetVillageArchitectTreeList()
          }
        } else {
          return false
        }
      })
    },
    // 关闭新增对话框
    closeAddDialog () {
      this.innerVisible = false
      this.$refs.addTreeFormRef.resetFields()
      // console.log(this.addTreeForm)
    },
    // 展示编辑对话框
    editItem (row) {
      this.editForm._id = row.id
      this.editForm.organize_name = row.name
      this.editForm.parent_id = row.parent_id
      this.modifyVisible = true
    },
    // 编辑请求
    fnUpdateVillageArchitect () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          console.log('编辑请求----')
          var res = await updateOrg({
            _id: this.editForm._id,
            organize_name: this.editForm.organize_name,
            parent_id: this.editForm.parent_id
          })
          if (res.Code === 200) {
            this.modifyVisible = false
            this.fngetVillageArchitectTreeList()
          }
        } else {
          return false
        }
      })
    },
    // 删除
    delItem (id) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.fndelVillageArchitect({ _ids: [id] })
        })
        .catch(() => {
          this.$msg.info('已取消删除！')
        })
    },
    // 删除请求
    async fndelVillageArchitect (obj) {
      console.log('删除请求')
      const res = await deleteOrganization(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fngetVillageArchitectTreeList()
    },
    // 上传附件
    async upload (item) {
      // console.log(item)
      var formData = new FormData()
      formData.append('file', item.file)
      // console.log(formData)
      const res = await uploadOrganizationFile(formData)
      if (res.data && res.data.length > 0) {
        this.importForm.file_path = res.data[res.data.length - 1].FilePath
        this.fileList = [
          {
            name: res.data[res.data.length - 1].FileName,
            url: res.data[res.data.length - 1].FilePath
          }
        ]
      }
      console.log('uploadOrganizationFile----', res)
    },
    // 导入
    async importSubmit () {
      console.log(this.importForm)
      this.errMsg = null
      this.$refs.importFormRef.validate(async valid => {
        if (valid) {
          console.log('导入请求')
          const res = await importArchitect({
            _id: this.id,
            file_path: this.importForm.file_path,
            parent_id: this.importForm.parent_id
              ? this.importForm.parent_id
              : this.id
          })
          console.log(res)
          if (res && res.Code === 200) {
            if (res.Data) {
              this.errMsg = res.Data
              this.importForm.file_path = ''
              this.fileList = []
            } else {
              this.$msg.success(res.Message)
              this.importDialog = false
            }
            this.fngetVillageArchitectTreeList()
          }
        } else {
          return false
        }
      })
    },
    // 关闭导入对话框时触发
    importDialogClose () {
      this.importDialog = false
      this.$refs.importFormRef.resetFields()
      this.fileList = []
      this.errMsg = null
      // console.log(this.importForm)
    },
    // 关闭组织架构对话框
    closeVisible () {
      this.isVisible = false
      this.$emit('closewindow')
    }
  }
}
</script>
<style scoped lang="less">
.upload_box {
  position: relative;
  display: flex;
  align-items: flex-start;
  .el-link {
    position: absolute;
    left: 100px;
  }
}
.err_box {
  color: #f56666;
  /deep/ .el-form-item__label {
    color: #f56666;
  }
}
.el-tree {
  max-height: 500px;
  overflow: auto;
}
.custom-tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    .iconfont {
      display: inline-block;
    }
  }
  .iconfont {
    display: none;
    margin-left: 10px;
  }
}
</style>
